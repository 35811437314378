<template>
  <div class="flex flex-col gap-2 h-full">
    <button type="button" @click="connectWallet()" class="button wallet">
      <wallet-icon class="text-wallet mr-2"/>
      {{
        this.$store.state.wallet != null
            ? `${this.$store.state.wallet[0].slice(
                0,
                4
            )}...${this.$store.state.wallet[0].slice(-4)}`
            : `Connect Wallet`
      }}
    </button>

    <a href="https://portal.moonsama.com/oauth?response_type=code&client_id=XZRzCdDAhuWImOpzCMIeFGoQTKCBlJhZ&state=dremica"
       class="button portal">
      <user-circle-icon class="text-portal mr-2"/>
      {{
        this.getCookie('uuid') != null
            ? `${this.getCookie('uuid').slice(
                0,
                4
            )}...${this.getCookie('uuid').slice(-4)}`
            : `Log in with portal`
      }}

    </a>
  </div>
</template>

<script>
import axios from "axios";
import {WalletIcon, UserCircleIcon} from "vue-tabler-icons";
import {API, CHAT, ELIGIBLE_ASSET_IDS, SCROLLS_ELIGIBLE} from "@/config";
import Config from "@/config.json";

export default {
  name: "WalletButton",
  components: {
    WalletIcon,
    UserCircleIcon,
  },
  created() {
    this.connectWallet();
  },
  mounted() {
    const url = window.location.href;
    console.log(url, 'url test')
    console.log(this.getCookie('uuid'))
    if (this.getCookie('uuid')) {
      console.log('present')

      this.checkExosamaEligibility()
    }
    if (url.includes('portal/oauth')) {
      const params = new URLSearchParams(window.location.search);

      this.saveUUID(params)
      window.location.href = '/';
    }
  },
  methods: {
    async saveUUID(params) {
      const uuid = await axios.get(`${Config.API}/portal/oauth/code?${params.toString()}`, {withCredentials: true})
          .then(data => {
            console.log(data);
            return data.data.uuid
          }).catch()
      this.setCookie('uuid', uuid);
      window.location.href = '/';
    },
    getCookie(id) {
      let value = document.cookie.match('(^|;)?' + id + '=([^;]*)(;|$)');
      return value ? value[2] : null;
    },
    setCookie(id, value) {
      document.cookie = id + '=' + value + ';path=/';
    },
    async connectWallet() {
      if (!window.ethereum) return;

      window.ethereum.removeListener("accountsChanged", this.getAccounts);
      window.ethereum.on("accountsChanged", this.getAccounts);

      this.getAccounts();
    },

    async getAccounts() {
      try {
        const data = await window.ethereum.request({
          method: "eth_requestAccounts",
        });

        this.$store.commit("wallet", data);

        this.checkExosamaEligibility()
      } catch (error) {
        console.error("Connection failed!");
      }
    },

    async checkScrollEligibility() {
      axios
          .get(`${API}/user/${this.$store.state.wallet ? this.$store.state.wallet[0] : this.getCookie('uuid')}/EMBASSY`, {
            params: {
              owner: this.$store.state.wallet[0],
              recognizedAsset: "EMBASSY",
              chainId: "MOONRIVER",
            },
          })
          .then((response) => {
            console.log(response)
            let isEligible = false

            if (SCROLLS_ELIGIBLE && response.data.assets.length > 0) {
              const ownedEligibleAssets = response.data.tokens.filter((e) =>
                  ELIGIBLE_ASSET_IDS.includes(e.assetId)
              );

              if (ownedEligibleAssets.length > 0) {
                isEligible = true
              }
            }

            if (isEligible) {
              this.$store.commit("eligible", true);
              this.getCredentials()
            } else {
              this.$store.commit("eligible", false);
            }
          })
          .catch((e) => console.log("Error retrieving MSAMA API data!", e));
    },

    async checkMoonsamaEligibility() {
      axios
          .get(
              `${API}/user/${this.$store.state.wallet ? this.$store.state.wallet[0] : this.getCookie('uuid')}}/EMBASSY`,
              {
                params: {
                  address: this.$store.state.wallet[0],
                },
              }
          )
          .then((response) => {
            if (response.data.total > 0) {
              const msamas = response.data.tokens.filter(e => e.location !== 'MARKETPLACE');
              if (msamas.length > 0) {
                this.$store.commit("eligible", true);
                this.getCredentials()
              } else {
                this.checkScrollEligibility();
              }
            } else {
              this.checkScrollEligibility();
            }
          });
    },

    async checkExosamaEligibility() {
      axios.get(`${API}/tokengate/${CHAT}/${this.$store.state.wallet ? this.$store.state.wallet[0] : this.getCookie('uuid')}/access`, {withCredentials: true})
          .then(() => {
            this.$store.commit("eligible", true);
            this.getCredentials()
          })
          .catch(() => {
            this.$store.commit("eligible", false);
          })
    },

    async getCredentials() {
      axios
          .get(`${API}/tokengate/${CHAT}/${this.$store.state.wallet ? this.$store.state.wallet[0] : this.getCookie('uuid')}/data`, {
            withCredentials: true,
          })
          .then((response) => {
            window.localStorage.setItem('data', JSON.stringify(response.data));
            this.$store.commit("credentials", response.data);
          })
          .catch((e) => {
            console.log("Error retrieving API data!", e);
          });
    },
  },
};
</script>